import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { useRouter } from 'next/router';

export const useElementIsInViewFirstTime = () => {
  const elementInViewRef = useRef(null);
  const intersection = useIntersection(elementInViewRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.7,
  });
  const [isObserving, setIsObserving] = useState(true);
  const [isInViewFirstTime, setIsInViewFirstTime] = useState(false);
  const router = useRouter();

  // disable observer on route change
  useEffect(() => {
    const handleRouteChange = () => {
      setIsInViewFirstTime(false);
      setIsObserving(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  // enable observer after small delay
  useEffect(() => {
    if (isObserving) {
      return;
    }

    const timeoutID = setTimeout(() => setIsObserving(true), 2500);

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [isObserving]);

  useEffect(() => {
    if (intersection?.isIntersecting && !isInViewFirstTime && isObserving) {
      setIsInViewFirstTime(true);
    }
  }, [intersection, isInViewFirstTime, isObserving]);

  return { elementInViewRef, isInViewFirstTime };
};

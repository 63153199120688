'use client';

import { useCallback } from 'react';
import Image from 'next/image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, CardActionArea, CardActions, Chip, Typography, Stack } from '@mui/material';
import { EcommerceItem } from '@qb/frontend/analytics/ga4Types';
import { recordSelectItemFromListForAnalytics } from '@qb/frontend/analytics/recordSelectItemFromListForAnalytics';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { QB_COMPANY_ID } from '@/shared/Constants';
import { PartRelevantPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { PartCardShipProcessingDays } from '@/src/components/Common/PartCards/components/PartCardShipProcessingDays';
import { useBuyNowRequest } from '@/src/components/PartDisplayPage/hooks/useBuyNowRequest';
type PartCardLargeProps = {
  part: PartRelevantPartsResponse['relevantParts'][0];
  onClick?: () => void;
  ga4data: {
    itemIndex: EcommerceItem['index'];
    itemListName: EcommerceItem['item_list_name'];
  };
};
export const PartCardLarge = ({
  part,
  onClick,
  ga4data
}: PartCardLargeProps) => {
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  const href = generatePartURL(part.manufacturer.name, part.partNumber, part.id);
  const {
    mutate: buyNow,
    isLoading
  } = useBuyNowRequest({
    partID: part.id,
    ga4data: {
      partNumber: part.partNumber,
      price: part.price,
      qty: 1,
      onHandQty: part.onHandQty,
      manufacturerStock: part.manufacturerStock,
      manufacturerName: part.manufacturer.name,
      partCategoryName: part.partCategory.name,
      itemListName: ga4data.itemListName
    }
  });
  const onPartView = useCallback(() => {
    if (onClick) {
      onClick();
    }
    recordSelectItemFromListForAnalytics({
      part,
      itemIndex: ga4data.itemIndex,
      itemListName: ga4data.itemListName
    });
  }, [onClick, part, ga4data.itemIndex, ga4data.itemListName]);
  const combinedStock = part.onHandQty + part.manufacturerStock;
  return <Card sx={{
    backgroundColor: 'background.paper',
    border: '2px solid',
    borderColor: 'primary.50p',
    overflow: 'hidden',
    borderRadius: 0
  }} data-sentry-element="Card" data-sentry-component="PartCardLarge" data-sentry-source-file="PartCardLarge.tsx">
      <CardActionArea onClick={onPartView} href={href} tabIndex={-1} sx={{
      px: 2,
      pt: 1,
      pb: 0.5
    }} data-sentry-element="CardActionArea" data-sentry-source-file="PartCardLarge.tsx">
        {combinedStock && !!part.price && <PartCardShipProcessingDays isStockFromFactory={!!part.manufacturerStock} shipProcessingDays={part.shipProcessingDays} />}
        <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
      }} data-sentry-element="Box" data-sentry-source-file="PartCardLarge.tsx">
          {isDiscounted && <Chip color="accent" label={`${discountPercentage}% off`} sx={{
          ml: 'auto',
          position: 'absolute',
          top: 0,
          right: -8
        }} />}
          <Image src={getFirstImage(part.images)} alt={`${part.partNumber} Part Image. Manufactured by ${part.manufacturer.name}.`} width={222} height={90} style={{
          objectFit: 'contain'
        }} data-sentry-element="Image" data-sentry-source-file="PartCardLarge.tsx" />
        </Box>
        <Typography variant="h4" component="h3" sx={{
        my: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.partNumber}
        </Typography>
        <Stack data-sentry-element="Stack" data-sentry-source-file="PartCardLarge.tsx">
          {isDiscounted && !!part.originalPrice && <Typography variant="bodySmall" fontWeight={600} sx={{
          color: 'text.secondary',
          textDecoration: 'line-through'
        }}>
              {formatCents(part.originalPrice)}
            </Typography>}
          {!!part.price && <Typography variant="bodyLargePrimary" fontWeight={600} color="text.primary" sx={{
          mb: 1
        }}>
              {formatCents(part.price)}
            </Typography>}
        </Stack>
        <Chip icon={<CheckCircleIcon />} color="success" size="small" label={<>
              Verified stock: <strong>{combinedStock.toLocaleString()}</strong>
            </>} sx={{
        width: 'fit-content',
        mb: 1.5,
        mt: 0.5,
        visibility: combinedStock ? 'visible' : 'hidden'
      }} data-sentry-element="Chip" data-sentry-source-file="PartCardLarge.tsx" />
        <Typography variant="bodyMedium" sx={{
        mb: 1,
        height: 51,
        ...clampLinesSx(3)
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.description}
        </Typography>
        <Typography fontWeight={600} color="text.primary" data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.manufacturer.name}
        </Typography>
      </CardActionArea>

      <CardActions sx={{
      mb: 1
    }} data-sentry-element="CardActions" data-sentry-source-file="PartCardLarge.tsx">
        <LoadingButton fullWidth variant="contained" sx={{
        whiteSpace: 'nowrap'
      }} loading={isLoading} tabIndex={-1} onClick={() => buyNow({
        partID: part.id,
        supplierID: QB_COMPANY_ID,
        qty: 1
      })} data-sentry-element="LoadingButton" data-sentry-source-file="PartCardLarge.tsx">
          Buy now
        </LoadingButton>
      </CardActions>
    </Card>;
};